html,body {
  /* height:100vh; */
  outline: none;
  background-color: #333;
}

body {
  background-color: #333;
}

Sidebar {
  position: fixed;
}

#sidebarContainer {
  background-color: #000;
  border: #fff;
  position: fixed;
}

.date-time {
  font-size: 10px;
  display: inline-block;
  color: #ccc;
  margin-right: 100px;
}

.meetingForm {
  margin: 25%;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 50%;
  background-color: #222;
  color: #fff;
  border: #fff 1px solid;
  border-radius: 5px;
  padding: 10px;
}

.specialTitle {
  text-align: center;
}
.whiteBar {
  background-color: #fff;
}
.fixDiv {
  padding-left: 20px;
}
#fullQuestion {
  min-height: 100vh;
}

.newBtn {
  vertical-align: center;

  /* width: 100px; */
  background-color:white;
  color:black;
  border:none;
  border-radius: 5px;
  font-size: 15px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 7px;
  padding-left: 10px;
  padding-right: 10px ;
  outline: none !important;
}

#searchBar {
  vertical-align: center;

  margin: 0px;
  margin-left: 20px;
  display: inline-block;
  padding: 7px;
  padding-left: 10px;
  padding-right: 10px ;
  width: 30%;
  border: none;
  border-radius: 5px;
  outline: none;
  font-size: 15px;
}
#title {

  margin: 0px;
  margin-left: 20px;
  margin-top: 2px !important;
  padding: 2px;
  /* margin-top: 2px; */
  text-align: bottom;
  vertical-align: center;
  /*padding-left: 10px;
  padding-right: 10px ; */

  display: inline;
  
  color: black;
  user-select: none;
  background: #f6c733;
  font-size: 25px;
  text-align: center;
  /* text-align: center;
  float: left;
  display: inline-block;
  padding: 0px 10px;
  margin-bottom: none !important;
  margin-left: 2%;
   */
}

#tags {
  vertical-align: center;

  width: 100px;
  display: inline;
  height: 37px;
  outline: none;
}


#titleArea {

  /* padding: center; */
  padding-bottom: 10px; 
  padding-top: 10px;
  position: fixed;
  /* height: 70px; */
  /* margin: none; */
  color: white;
  background-color: #222;
  width: 100%;
  z-index: 9;
}



.footertext {
  margin: 0;
}

#pfp-title {
  text-align: center;
}

.main {
  background-color: blue;
  /* flex: auto; */
  display: flex;
}

.feed {
  padding: 20px;
  position: absolute;
  width: 80%;
  
  margin: auto;
  margin-left: 15%;
  margin-top: 100px;
  
}

#sidebarButton {
  /* margin-top: 300px; */
  /* float: right; */
  position: fixed;
  top: 80px;
  right: 40px;
  width: 50px;
  height: 50px;
  color: #000;
  background-color: #fff;
  border-radius: 5px;
  outline: none;
  border: none;
  font-size: 20px;

}



.updown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#uploadFile {
  border-radius: 5px;
  border: 1px solid;
  border-radius: 5px;
  border: #222222;
  color: white;
  background-color: #111111;
  max-width: 100px;
  outline: none;
}

/* 
.sidePanel {
  display: block;
  color: white;
  background-color: #222;
  text-align: left;
  width: 25%;
  height: 100%;
  position: fixed;
} */

.alertcenter {
  width: 50%;
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;
}

.alert {
  border: 1px solid #f6c733;
  border-radius: 5px;
  color: #000;
  background-color: #f6c733;
}

.closed {
  display: none;
}

.notification p {
  margin: 0;
}

.open {
  display: block;
}

.link-dark {
  color: white;
  font-size: 15px;
}

.link-light {
  color: black;
  font-size: 15px;
}

.link-dark:hover {
  color: white;
}

.link-light:hover {
  color: black;
}

.sbox {
  margin-bottom: 10px;
  padding: 10px;
  color: white;
  border: 1px black;
  border-radius: 5px;
  /* position: fixed; */
}

.copyright {
  font-size: 10px;
}

button {

  outline: none;
}

.questionBox {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
  padding-bottom: 2px;
  display: block;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

#places {
  text-align: center;
  margin: 40%;
  margin-top: 12px;
  margin-bottom: 10px;
  padding-bottom: 2px;
  display: inline-block;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 20%;
  outline: none;
  color: white;
}

.tickBoxes {
  color: white;
}

#checkBoxSelect {
  text-align: left;
  margin: 10%;
  margin-top: 12px;
  margin-bottom: 10px;
  padding-bottom: 2px;
  display: inline-block;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 80%;
  outline: none;
  color: white;
}

#anonymousBoxLabel{
  color: white;
}
#checkBoxTitle{
  text-align: center;
  align-content: center;
  font-size: 15px;
}

/* SPACERS */

#spacer1 {
  padding: 10px;
}

.tickBoxSurround {
  display: inline-block;
  width: 200px;
}


#submitClasses {
  margin-left: 40%;
  width: 20%;
}
.tickBoxes {
  
}
.upvotes-num {
  text-align: center;
  margin: 0;
}

#answerBox {
  border: solid;
  padding: 10px;  
  border-width: 1px;
  border-radius: 5px;
  margin-bottom: 10px;
  outline: none !important;
  
}

#replyBox {
  border: solid;
  padding: 10px;  
  border-width: 1px;
  border-radius: 5px;
  height: 100px;
  width: 100%;
}

.questionBox:hover {
  cursor: pointer;
  
}

.aboutname {
  font-size: 1em;
}

.feed-list {
  list-style-type: none;
  height: 100%;
  padding: 0px;
  margin-left: 5%;
}

@media(max-width: 400px) {
  .questionBox {
    margin: 0;
    margin-top: 5px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
    width: 100%;
  }

  .feed-list .container li:last-of-type {
    border-bottom: none;
  }

  .sidePanel {
    display: none;
  }

  #profileMenu {
    float: right !important;
  }

  .cardcontainer {
    flex-direction: column;
  }

  .container .card {
    margin-top: 10px;
    margin-bottom: 10px;
  }

}


.dropdown-item {

}

#socialDrop {
  float: right !important;
  margin-top: 0px;

}

#logOut {
  /* margin: 0; */
  display: inline-block;
  
  float: right;
  border-radius: 100%;
  width: 50px;
  margin-right: 25px;

}


#logIn {
  /* margin-left: 20%; */
  /* margin: 0; */
  /* display: inline-block; */
  /* float: right; */


}


a {
  font-weight: bold;
  color: #0070d1;
}



.seeFull {
  display: inline-block;
  float: right;
  margin-right: 10px;
  font-size: 15px;
}

.timeStamp {
  display: inline-block;
  float: left;
}

.voteButton {
  text-align: none !important;
  outline: none !important; 
  margin-top: 0px !important;
  

  display: block;
  border: none;
  padding: none;
  padding-left: 10px;
}

#middleText {
  text-align: center;
}

.footer {
  padding: 30px;
  width: 100%;
  position: relative;
  bottom: 0;
}

.links {
  font-weight: bold;
  color: #0070d1;
  text-decoration: none;
  -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
}
.links:hover {
  text-decoration: underline;
}

.loader {
  margin: 25%;
  margin-left: 30%;
}

.cardcontainer {
  display: flex;
  flex-direction: row;
}

#profileMenu {
  float: right;
  margin-top: 45px !important;
}

.post-img {
  /* images that have been posted*/
  max-height: 500px;
  max-width: 30%;
  width: 35%;
  margin: auto;
  outline: none;
}

.pf-title {
  color: white;
  text-align: center;
}

.pf-questionBox {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
  padding-bottom: 2px;
  display: block;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.list-posts {
  width: 80%;
  margin: auto;
}

.containerthread {
  padding: 10px;
}