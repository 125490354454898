html,body {
  height:100vh;
  outline: none;
  background-color: #333;
  min-width: 500px;
}

body {
  background-color: #333;
}

li {
  background-color: none;
}


.date-time {
  font-size: 10px;
  display: inline-block;
  color: #ccc;
  margin-right: 100px;
}

.meetingForm {
  margin: 25%;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 50%;
  background-color: #222;
  color: #fff;
  border: #fff 1px solid;
  border-radius: 5px;
  padding: 10px;
}

.specialTitle {
  text-align: center;
}
.whiteBar {
  background-color: #fff;
}
.fixDiv {
  padding-left: 20px;
}
#fullQuestion {
  min-height: 100vh;
}
#titleArea2 {
  /* margin-bottom: -30px; */
  padding: 10px;
  height: 80px;
  width: 100%;
  background-color: #222;
  color: #fff;
}
#title {
  /* text-align: center;
  float: left;
  display: inline-block;

  color: black;
  background: #f6c733;
  padding: 0px 10px;
  user-select: none;
  margin-bottom: none !important;
  margin-left: 2%;
  font-size: 25px; */
}

/* .newBtn { */
  /* width: 100px;
  background-color: white;
  color:black;
  border:none;
  border-radius: 5px;
  font-size: 15px;
  padding: 7px;
  padding-left: 10px;
  padding-right: 10px ;
} */

.footertext {
  margin: 0;
}

#pfp-title {
  text-align: center;
}

.pf {
  
  border-radius: 100%;
}

.main {
  background-color: blue;
  /* flex: auto; */
  display: flex;
}





.alertcenter {
  width: 50%;
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;
}

.alert {
  border: 1px solid #f6c733;
  border-radius: 5px;
  color: #000;
  background-color: #f6c733;
}

.link-dark {
  color: white;
  font-size: 15px;
}

.link-light {
  color: black;
  font-size: 15px;
}

.link-dark:hover {
  color: white;
}

.link-light:hover {
  color: black;
}

.sbox {
  margin-bottom: 10px;
  padding: 10px;
}

.copyright {
  font-size: 10px;
}

button {

  outline: none;
}



.tickBoxes {
  color: white;
}

#checkBoxSelect {
  text-align: left;
  margin: 10%;
  margin-top: 12px;
  margin-bottom: 10px;
  padding-bottom: 2px;
  display: inline-block;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 80%;
  outline: none;
  color: white;
}

#anonymousBoxLabel{
  
}
#checkBoxTitle{
  text-align: center;
  align-content: center;
  font-size: 15px;
}

/* SPACERS */

#spacer1 {
  padding: 10px;
}

.tickBoxSurround {
  display: inline-block;
  width: 200px;
}


#submitClasses {
  margin-left: 40%;
  width: 20%;
}
.tickBoxes {
  
}
.upvotes-num {
  text-align: center;
  margin: 0;
}

.aboutname {
  font-size: 1em;
}


#socialDrop {
  float: right !important;
  margin-top: 0px;

}

a {
  font-weight: bold;
  color: #0070d1;
}



.seeFull {
  display: inline-block;
  float: right;
  margin-right: 10px;
  font-size: 15px;
}

.timeStamp {
  display: inline-block;
  float: left;
}

.voteButton {
  text-align: none !important;
  outline: none !important; 
  margin-top: 0px !important;
  

  display: block;
  border: none;
  padding: none;
  padding-left: 10px;
}

#middleText {
  text-align: center;
}

.footer {
  padding: 30px;
  width: 100%;
  position: relative;
  bottom: 0;
}

.links {
  font-weight: bold;
  color: #0070d1;
  text-decoration: none;
  -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
}
.links:hover {
  text-decoration: underline;
}

.loader {
  margin: 25%;
  margin-left: 30%;
}

.pf-title {
  color: white;
  text-align: center;
}

.pf-questionBox {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
  padding-bottom: 2px;
  display: block;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.list-posts {
  width: 80%;
  margin: auto;
}




.list-group {
  background-color: none;

}

#classList {
  background-color: none;
}

.classListItems {
  background-color: none;
  color: black;
}


.list-group-item {
  background-color: none;
  color: white;
  margin: 10px;
}

.containerthread {
  padding: 10px;
}
